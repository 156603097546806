/** @jsxImportSource @emotion/react */

import { keyframes, css } from "@emotion/react";

const buttonAnimation = keyframes`
from {
opacity: 0;
}
to {
opacity: 1;
}
`;

export default function Button({ children }) {
  const buttonStyles = css`
    display: inline-block;
    color: white;
    background-color: rgb(0 188 212 / 78%);
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 12px;
    padding: 46px 78px;
    border-top-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    opacity: 0;
    animation: ${buttonAnimation} 1s ease-in forwards 1.25s;
    transition: border-radius 200ms;

    :hover {
      border-top-left-radius: 0;
      border-top-right-radius: 1.5rem;
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 0;
    }
  `;

  return <div css={buttonStyles}>{children}</div>;
}
