/** @jsxImportSource @emotion/react */

import { css, keyframes } from "@emotion/react";
import mouseIcon from "../../assets/mouse-icon.svg";

const animation = keyframes`
from {
opacity: 0;
  transform: translateY(-.5rem);
}
to {
opacity: 1;
transform: translateY(0);
}
`;

const containerStyles = css`
  display: inline-block;
  transform: translateY(-.5rem);
  opacity: 0;
  animation: ${animation} 1s ease-in forwards 1.8s;
`;

const imgStyles = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const textStyles = css`
  color: #767e90;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 0.5rem;
  margin-top: 0.5rem;
`;

export default function ScrollIcon() {
  return (
    <div css={containerStyles}>
      <img css={imgStyles} src={mouseIcon} alt="mouse icon" />
      <p css={textStyles}>SCROLL DOWN</p>
    </div>
  );
}

