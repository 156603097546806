/** @jsxImportSource @emotion/react */

import { css, keyframes } from "@emotion/react";
import { useState } from "react";
import Button from "../Button";
import LandingPageFlair from "../LandingPageFlair";
import MobileMenuScreen from "../MobileMenuScreen";
import { Divide as Hamburger } from "hamburger-react";
import ScrollIcon from "../ScrollIcon";
import VerticalSeparator from "../VerticalSeparator";
import backgroundDots from "../../assets/bg-dots.svg";
// import burgerMenuIcon from "../../assets/burger-menu-icon.svg";
// import mailIcon from "../../assets/mail-icon.svg";
import sunIcon from "../../assets/Sun.svg";

const fadeIn = keyframes`
from {
opacity: 0;
}
to {
opacity: 1;
}
`;

const slideDown = keyframes`
from {
transform: translateY(1rem);
opacity: 0;
}
to {
transform: translateY(0rem);
opacity: 1;
}
`;

const navStyles = css(css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 900;

  animation: ${fadeIn} 1s ease-in forwards;
`);

const welcomeStyles = css`
  color: white;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 40px;
  opacity: 0;
  animation: ${slideDown} 1s ease-in forwards;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const headingStyles = css`
  color: white;
  font-family: "Playfair Display", serif;
  font-weight: 900;
  font-size: 80px;
  margin-top: 0.5rem;
  opacity: 0;
  animation: ${slideDown} 1s ease-in forwards 0.5s;
`;

const contentContainerStyles = css`
  position: relative;
  margin-top: 3rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    width: 7rem;
    height: 7rem;
    background-image: url(${backgroundDots});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 7rem;
    height: 7rem;
    background-image: url(${backgroundDots});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media (min-width: 768px) {
    text-align: center;

    &::after {
      bottom: initial;
    }
  }

  @media (min-width: 1024px) {
    &::before {
      right: -6rem;
    }
    &::after {
      display: none;
    }
  }
`;

const contentStyles = css`
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1.5rem;
  line-height: 1.5;
  opacity: 0;
  animation: ${slideDown} 1s ease-in forwards 1s;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  @media (min-width: 1024px) {
    max-width: 500px;
    text-align: right;
  }
`;

const homePageStyles = css`
  position: relative;
  background-color: #232323;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 4rem;
  }
`;

const mobileMenuStyles = css`
  @media (min-width: 1024px) {
    display: none;
  }
`;

const languageMenuStyles = css`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  gap: 0.5rem;
  color: rgba(255 255 255 / 60%);

  .language-separator {
    width: 1px;
    height: 16px;
    background-color: rgba(255 255 255 / 40%);
  }

  .active-language {
    font-weight: 700;
    color: white;
  }
`;

const navContactStyles = css`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    margin-left: 2rem;
    margin-right: auto;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 10px;
    background-color: transparent;
    border: 1px solid rgb(0 188 212 / 78%);
    border-radius: 16px;
    box-sizing: border-box;
    height: 100%;
    padding: 0.5rem 2rem;
    transition: background-color 400ms, color 400ms;

    :hover {
      background-color: rgba(0 188 212);
      color: #232323;
    }
  }
`;

export default function HomePage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div css={homePageStyles}>
      <MobileMenuScreen toggled={isMenuOpen} toggleMenu={setIsMenuOpen} />
      <LandingPageFlair />
      <nav css={navStyles}>
        <p>romofel</p>
        <button css={navContactStyles}>CONTACT ME</button>
        <ul
          className="menu"
          css={css`
            display: flex;
            align-items: center;
            gap: 2rem;
          `}
        >
          <li>
            <ul css={languageMenuStyles} className="language-menu">
              <li className="active-language">en</li>
              <div className="language-separator"></div>
              <li>sp</li>
            </ul>
          </li>
          <li>
            <img src={sunIcon} alt="sun icon" />
          </li>
        </ul>
        <div css={mobileMenuStyles} className="mobile-menu">
          {/* <img src={burgerMenuIcon} alt="burger menu icon for mobile" /> */}
          <Hamburger
            color="white"
            toggled={false}
            onToggle={(_) => setIsMenuOpen(true)}
          />
        </div>
      </nav>

      <div css={contentContainerStyles}>
        <h1 css={welcomeStyles}>Hi, welcome to</h1>
        <h1 css={headingStyles}>Romofel.</h1>
        <p css={contentStyles}>
          FullStack Developer; Frontend Aficionado; Creative Thinker; Problem
          Solver
        </p>
      </div>

      <div
        css={css`
          margin-top: 2rem;
        `}
        className="separator"
      ></div>
      <Button>MY WORK</Button>
      <div className="landing-separator"></div>
      <div
        css={css`
          margin-top: 2.5rem;
        `}
        className="separator"
      ></div>
      <VerticalSeparator />
      <div
        css={css`
          margin-top: 1.37rem;
        `}
        className="separator"
      ></div>
      <ScrollIcon />
    </div>
  );
}
