/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Divide as Hamburger } from "hamburger-react";

const screenStyles = css`
  position: absolute;
  top: -100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: #00bcd4;
  padding: 1.5rem;
  transition: top 400ms cubic-bezier(.47,1.64,.41,.8);

  &.active {
    top: 0;
  }
`;

const navStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #232323;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 900;
`;

const navMenuStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  font-family: "Roboto", sans-serif;
  color: #232323;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  & li:not(:first-of-type) {
    margin-top: 5rem;
  }
`;

const socialMenuStyles = css`
  display: flex;
  justify-content: space-around;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 900;
  color: #394153;
  width: 100%;
`;

export default function MobileMenuScreen({ toggled, toggleMenu }) {
  return (
    <div css={screenStyles} className={toggled && 'active'}>
      <nav css={navStyles}>
        <p>romofel.</p>
        <Hamburger
          color="#232323"
          toggled={true}
          onToggle={(_) => {
            toggleMenu(false);
          }}
        />
        {/* <div className="close-menu"></div> */}
      </nav>
      <ul css={navMenuStyles}>
        <li>ABOUT</li>
        <li>WORK</li>
        <li>CONTACT</li>
      </ul>

      <ul css={socialMenuStyles}>
        <li>github</li>
        <li>linkedin</li>
        <li>youtube</li>
      </ul>
    </div>
  );
}
