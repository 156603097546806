/** @jsxImportSource @emotion/react */

import { css, keyframes } from "@emotion/react";

const animation = keyframes`
from {
opacity: 0;
  transform: translateY(-2rem);
}
to {
opacity: 1;
transform: translateY(0);
}
`;

const styles = css`
  background-color: #767e90;
  width: 1px;
  height: 100px;
  opacity: 0;
  transform: translateY(-2rem);
  animation: ${animation} 1s ease-in forwards 1.25s;
`;
export default function VerticalSeparator() {
  return <div css={styles}></div>;
}

