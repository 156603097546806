/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import halfCircle from "../../assets/half-circle.svg";
import crossIcon from "../../assets/CrossIcon.svg";
// import greyCrossIcon from "../../assets/grey-cross.svg";
import farTopRightDotsIcon from "../../assets/FarTopRightDots.svg";
import largeGroupDotsIcon from "../../assets/large-group-dots.svg";
import midSizeDotGroupIcon from "../../assets/mid-size-dot-group.svg";

function Flair({ children, ...props }) {
  return (
    <div
      css={css`
        display: none;

        @media (min-width: 1024px) {
          display: block;
          position: absolute;
          ${props}
        }
      `}
    >
      {children}
    </div>
  );
}

export default function LandingPageFlair() {
  return (
    <>
      <Flair left={0} top={"-5rem"} opacity={0.5}>
        <img src={halfCircle} alt="half circle flair" />
      </Flair>
      <Flair top={"1rem"} right={"4rem"} opacity={0.5}>
        <img src={farTopRightDotsIcon} alt="double row of dots" />
      </Flair>
      <Flair left={"4rem"} bottom={"4rem"} opacity={0.5}>
        <img src={largeGroupDotsIcon} alt="double row of dots" />
      </Flair>
      <Flair right={"4rem"} top={"50%"} opacity={0.5}>
        <img src={midSizeDotGroupIcon} alt="double row of dots" />
      </Flair>
      <Flair opacity={0.5}>
        <img src={crossIcon} alt="double row of dots" />
      </Flair>
    </>
  );
}
